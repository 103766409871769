import React, { useCallback, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import { useStyles } from "components/CodeInput/styles";

interface CodeInputProps {
  autoCode: string;
  verifyCode: Function;
}

const CodeInput = (props: CodeInputProps): JSX.Element => {
  const classes = useStyles();
  const [key, setKey] = React.useState(0);

  const handleChange = useCallback((value: string): void => {
    if (value.length === 4) {
      props.verifyCode({ variables: { code: value } });
    }
  }, [props]);

  useEffect(() => {
      setKey(key + 1);
      setTimeout(() => handleChange(props.autoCode), 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.autoCode, handleChange]);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.description}>Enter the code below</div>
        <ReactCodeInput
          key={key}
          value={props.autoCode}
          type="number"
          fields={4}
          name={"code"}
          inputMode={"numeric"}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default CodeInput;
